/*　include
------------------------------------------------------- */

import "core-js/stable";
import "regenerator-runtime/runtime";
import './scss/style.scss';

/*
------------------------------------------------------- */
objectFitImages();

let webStorage = function(){

if(sessionStorage.getItem('access')){

document.addEventListener('DOMContentLoaded', (event) => {
  setTimeout(function() {
    $('#mvVideo').addClass('visible');
  }, 4400);
});

document.addEventListener('DOMContentLoaded', (event) => {
  setTimeout(function() {
    $('.ly_mv_txt').addClass('color');
  }, 4800);
});

document.addEventListener('DOMContentLoaded', (event) => {
  setTimeout(function() {
    $('#js_splash').addClass('end');
    $('.wrap').addClass('visible');
    $('.ly_mv .ly_mv_txt h2 span.box:nth-of-type(1) span').addClass('ttl-a');
  }, 0);
});

document.addEventListener('DOMContentLoaded', (event) => {
  setTimeout(function() {
    $('.ly_mv .ly_mv_txt h2 span.box:nth-of-type(2) span').addClass('ttl-a');
  }, 200);
});

document.addEventListener('DOMContentLoaded', (event) => {
  setTimeout(function() {
    $('.ly_mv .ly_mv_txt h2 span.box:nth-of-type(3) span').addClass('ttl-a');
  }, 800);
});

document.addEventListener('DOMContentLoaded', (event) => {
setTimeout(function() {
let container = $("#type");
let speed = 150;
let content = container.html();
let text = $.trim(content);
let newHtml = "";
text.split("").forEach(function(v) {
newHtml += '<span>' + v + '</span>';
});
container.html(newHtml);
let txtNum = 0;
container.css({opacity: 1});
setInterval(function() {
container.find('span').eq(txtNum).css({opacity: 1});
txtNum++
}, speed);
}, 1600);
});

function opAnimation() {
    gsap.to(".ly_mv_txt", 1, {
      color: "#fff",
      delay: 0
    });
    gsap.to(".ly_mv_txt", 1, {
      color: "#fff",
      delay: 0.7
    });
    gsap.to(".ly_mv_txt", 1, {
      color: "#fff",
      delay: 1.4
    });
    gsap.to(".ly_mv_txt", 1, {
      color: "#fff",
      delay: 2.1
    });
    gsap.to(".ly_mv_txt", 1, {
      color: "#fff",
      delay: 2.8
    });
    gsap.to(".ly_mv_txt", 1, {
      color: "#121212",
      delay: 4.2
    });
    gsap.to("#type", 1, {
      color: "#bababa",
      delay: 4.2
    });
}

$(document).ready(function(){
setTimeout(function() {
    opAnimation();
},400);
});

document.addEventListener('DOMContentLoaded', (event) => {
  setTimeout(function() {
    $('.js_top-mv_bg01').addClass('add');
  }, 600);
});
document.addEventListener('DOMContentLoaded', (event) => {
  setTimeout(function() {
    $('.js_top-mv_bg02').addClass('add');
  }, 1600);
});
document.addEventListener('DOMContentLoaded', (event) => {
  setTimeout(function() {
    $('.js_top-mv_bg03').addClass('add');
  }, 2600);
});
document.addEventListener('DOMContentLoaded', (event) => {
  setTimeout(function() {
    $('.js_top-mv_bg04').addClass('add');
  }, 3600);
});
document.addEventListener('DOMContentLoaded', (event) => {
  setTimeout(function() {
    $('.js_top-mv_bg05').addClass('add');
  }, 4600);
});

}else{

sessionStorage.setItem('access', 'true');

$(document).ready( function(){
    let animation = bodymovin.loadAnimation({
    container: document.getElementById('js_splash-ttl'), 
    renderer: 'svg',
    loop: false,
    autoplay: true,
    path: 'https://issei-inc.co.jp/wp-content/themes/issei/data.json'
    });
});

document.addEventListener('DOMContentLoaded', (event) => {
  setTimeout(function() {
    $('#mvVideo').addClass('visible');
  }, 9800);
});

document.addEventListener('DOMContentLoaded', (event) => {
  setTimeout(function() {
    $('.ly_mv_txt').addClass('color');
  }, 10200);
});

document.addEventListener('DOMContentLoaded', (event) => {
  setTimeout(function() {
    $('#js_splash').addClass('end');
    $('.wrap').addClass('visible');
    $('.ly_mv .ly_mv_txt h2 span.box:nth-of-type(1) span').addClass('ttl-a');
  }, 5400);
});

document.addEventListener('DOMContentLoaded', (event) => {
  setTimeout(function() {
    $('.ly_mv .ly_mv_txt h2 span.box:nth-of-type(2) span').addClass('ttl-a');
  }, 5800);
});

document.addEventListener('DOMContentLoaded', (event) => {
  setTimeout(function() {
    $('.ly_mv .ly_mv_txt h2 span.box:nth-of-type(3) span').addClass('ttl-a');
  }, 6400);
});

document.addEventListener('DOMContentLoaded', (event) => {
setTimeout(function() {
var container = $("#type");
var speed = 150;
var content = container.html();
var text = $.trim(content);
var newHtml = "";
text.split("").forEach(function(v) {
newHtml += '<span>' + v + '</span>';
});
container.html(newHtml);
var txtNum = 0;
container.css({opacity: 1});
setInterval(function() {
  container.find('span').eq(txtNum).css({opacity: 1});
txtNum++
}, speed);
  }, 7200);
});

function opAnimation() {
    gsap.to(".ly_mv_txt", 1, {
      color: "#fff",
      delay: 0
    });
    gsap.to(".ly_mv_txt", 1, {
      color: "#fff",
      delay: 0.7
    });
    gsap.to(".ly_mv_txt", 1, {
      color: "#fff",
      delay: 1.4
    });
    gsap.to(".ly_mv_txt", 1, {
      color: "#fff",
      delay: 2.1
    });
    gsap.to(".ly_mv_txt", 1, {
      color: "#fff",
      delay: 2.8
    });
    gsap.to(".ly_mv_txt", 1, {
      color: "#121212",
      delay: 4.2
    });
    gsap.to("#type", 1, {
      color: "#bababa",
      delay: 4.2
    });
}

$(document).ready(function(){
setTimeout(function() {
    opAnimation();
},6000);
});

document.addEventListener('DOMContentLoaded', (event) => {
  setTimeout(function() {
    $('.js_top-mv_bg01').addClass('add');
  }, 6200);
});
document.addEventListener('DOMContentLoaded', (event) => {
  setTimeout(function() {
    $('.js_top-mv_bg02').addClass('add');
  }, 7200);
});
document.addEventListener('DOMContentLoaded', (event) => {
  setTimeout(function() {
    $('.js_top-mv_bg03').addClass('add');
  }, 8200);
});
document.addEventListener('DOMContentLoaded', (event) => {
  setTimeout(function() {
    $('.js_top-mv_bg04').addClass('add');
  }, 9200);
});
document.addEventListener('DOMContentLoaded', (event) => {
  setTimeout(function() {
    $('.js_top-mv_bg05').addClass('add');
  }, 10200);
});

  }

}

webStorage();



$('.ly_header_btn').click(function() {
    $(this).toggleClass('clicked');
    $('html').toggleClass('stop');
    $('.ly_header_content').toggleClass('open');
    $('.ly_header_content_mask').toggleClass('open');
    $('.ly_header_content').fadeToggle();
})

$('.ly_header_btn.clicked').click(function() {
    $('.ly_header_content').addClass('close');
})

let tl = gsap.timeline({ 
  paused: true, 
  defaults: { duration: 0.6 }
})
.to(".ly_header_content.open", { opacity: 0 })
.to(".ly_header_content.open", { opacity: 1 }, "+=1")
function doFade() {
  tl.restart();
}
document.querySelector(".ly_header_btn").addEventListener("click", doFade);

let tl2 = gsap.timeline({ 
  paused: true, 
  defaults: { duration: 0.6 }
})
.to(".ly_header_content.close", { opacity: 1 })
.to(".ly_header_content.close", { opacity: 0 }, "+=1")
function doFade2() {
  tl2.restart();
}
document.querySelector(".ly_header_btn").addEventListener("click", doFade2);
 
$(window).scroll(function () {
  let height = $(window).height();
  if ($(window).scrollTop() > height) {
    $('header').addClass('fixed');
  } else {
    $('header').removeClass('fixed');
  }
});

$(function(){
  let pagetop = $('.el_top-btn');
  pagetop.hide();

  $(window).scroll(function () {
     if ($(this).scrollTop() > 1100) {
          pagetop.fadeIn();
     } else {
          pagetop.fadeOut();
     }
  });
});

$(function() {
  $('.fade').on('inview', function(event, isInView) {
    if (isInView) {
      $(this).addClass('fadeInC');
    } else {

    }
  });
});

$(function(){
  $('a[href^="#"]').click(function(){
    let speed = 500;
    let href= $(this).attr("href");
    let target = $(href == "#" || href == "" ? 'html' : href);
    let position = target.offset().top;
    $("html, body").animate({scrollTop:position}, speed, "swing");
    return false;
  });
});



let userAgent = window.navigator.userAgent.toLowerCase();
if(userAgent.indexOf('MSIE') != -1 || userAgent.indexOf('trident') != -1) {

let vecLocation1;
let vecVelocity1;
let vecLocation2;
let vecVelocity2;
let vecLocation3;
let vecVelocity3;
let vecLocation4;
let vecVelocity4;
let vecLocation5;
let vecVelocity5;

let img1;
let img2;

let progress = 0;
let orientation = 0;

let progress2 = 0;
let orientation2 = 0;

let progress3 = 0;
let progress4 = 0;
let progress5 = 0;
let progress6 = 0;

let progress3sp = 0;
let progress4sp = 0;
let progress5sp = 0;
let progress6sp = 0;

let progress3b = 0;
let progress4b = 0;
let progress5b = 0;
let progress6b = 0;

let progress3bsp = 0;
let progress4bsp = 0;
let progress5bsp = 0;
let progress6bsp = 0;

let angle = 0;
let count = 0;

const mqs = window.matchMedia('(min-width: 768px)');

mqs.addListener(changeMediaQuery);

function changeMediaQuery(mqs){
if (mqs.matches === true) {
        // 768px以上
let sketch1 = function(p) {

    //p.preload = function() {
    //    img1 = p.loadImage('https://issei-inc.co.jp/wp-content/themes/issei/img/service_bg_img.png');
    //};

    p.setup = function() {
        let canvas = p.createCanvas(p.windowWidth, 563);
        canvas.parent('#js_service_bg1');

        vecLocation1 = p.createVector(0, 460);
        vecVelocity1 = p.createVector(-0.05, -0.05);
        vecLocation2 = p.createVector(window.innerWidth / 2.5, 160);
        vecVelocity2 = p.createVector(-0.05, -0.05);
        vecLocation3 = p.createVector(window.innerWidth / 3.5, 260);
        vecVelocity3 = p.createVector(-0.05, 0.05);
        vecLocation4 = p.createVector(window.innerWidth / 1.5, 400);
        vecVelocity4 = p.createVector(-0.05, -0.05);
        vecLocation5 = p.createVector(window.innerWidth, 260);
        vecVelocity5 = p.createVector(-0.05, -0.05);

    };

    p.windowResized = function() {
      p.resizeCanvas(p.windowWidth, 563);
    };

    $('#js_service_bg1').on('inview', function(event, isInView) {
    if (isInView) {
        $('#js_service_bg1').delay(100).queue(function(){
            $(this).css('opacity','1');
        });
        p.draw = function() {
            p.loop();
            vecLocation1.add(vecVelocity1);
            vecLocation2.add(vecVelocity2);
            vecLocation3.add(vecVelocity3);
            vecLocation4.add(vecVelocity4);
            vecLocation5.add(vecVelocity5);

            p.background(255);
            p.fill(255, 0);
            p.noStroke();

            let pos = p.map(progress, 0, 1, 0, 3.14);
            let posA = p.map(progress3, 0, 1, 0, 3.14);
            let posB = p.map(progress4, 0, 1, 0, 3.14);
            let posC = p.map(progress5, 0, 1, 0, 3.14);

            let start = orientation + 6.28;

            if(p.frameCount > 20){
              if(progress3 < 1.99) {
                progress3 += 0.035;
              }
            }
            if(p.frameCount > 30){
              p.noFill();
              p.stroke(0,153,68);
              p.strokeWeight(40);
              let arc01 = p.arc(vecLocation1.x, vecLocation1.y, 300, 300, 0, start + posA);
            }

            p.push();

            if(p.frameCount > 30){
              if(progress4 < 1.99) {
                progress4 += 0.035;
              }
            }
            if(p.frameCount > 40){
              p.noFill();
              p.stroke(208,17,27);
              p.strokeWeight(40);
              let arc02 = p.arc(vecLocation2.x, vecLocation2.y, 300, 300, 0, start + posB);
            }
            p.pop();

            //p.image(img1, vecLocation3.x, vecLocation3.y, 230, 230);

            p.push();
            if(p.frameCount > 40){
              if(progress5 < 1.99) {
                progress5 += 0.035;
              }
            }
            if(p.frameCount > 50){
              p.noFill();
              p.stroke(253,208,0);
              p.strokeWeight(40);
              let arc04 = p.arc(vecLocation4.x, vecLocation4.y, 300, 300, 0, start + posC);
            }
            p.pop();

            p.noFill();
            p.stroke(0,58,143);
            p.strokeWeight(40);
            let arc05 = p.arc(vecLocation5.x, vecLocation5.y, 300, 300, 0, start + pos);
            if(progress < 1.99) {
                progress += 0.035;
            }

            if( vecLocation1.x < 0 || vecLocation1.x > window.innerWidth){
                vecVelocity1.x = vecVelocity1.x * -1;
            }
            if(vecLocation1.y < 0 || vecLocation1.y > 460){
                vecVelocity1.y = vecVelocity1.y * -1;
            }

            if( vecLocation2.x < 0 || vecLocation2.x > window.innerWidth){
                vecVelocity2.x = vecVelocity2.x * -1;
            }
            if(vecLocation2.y < 0 || vecLocation2.y > 460){
                vecVelocity2.y = vecVelocity2.y * -1;
            }

            if( vecLocation3.x < 0 || vecLocation3.x > window.innerWidth){
                vecVelocity3.x = vecVelocity3.x * -1;
            }
            if(vecLocation3.y < 0 || vecLocation3.y > 460){
                vecVelocity3.y = vecVelocity3.y * -1;
            }

            if( vecLocation4.x < 0 || vecLocation4.x > window.innerWidth){
                vecVelocity4.x = vecVelocity4.x * -1;
            }
            if(vecLocation4.y < 0 || vecLocation4.y > 460){
                vecVelocity4.y = vecVelocity4.y * -1;
            }

            if( vecLocation5.x < 0 || vecLocation5.x > window.innerWidth){
                vecVelocity5.x = vecVelocity5.x * -1;
            }
            if(vecLocation5.y < 0 || vecLocation5.y > 460){
                vecVelocity5.y = vecVelocity5.y * -1;
            }

        }
        p.mouseWheel = function(event) {
            vecLocation1.y += event.delta / 15;
            vecLocation2.y += event.delta / 15;
            vecLocation3.y += event.delta / 15;
            vecLocation4.y += event.delta / 15;
            vecLocation5.y += event.delta / 15;
        }
        } else {
            p.mouseWheel = function(event) {
                event.stopPropagation();
        }
        p.noLoop();
    }
  });
};

let sketch2 = function(p) {
    
    //p.preload = function() {
    //    img2 = p.loadImage('https://issei-inc.co.jp/wp-content/themes/issei/img/service_bg_img2.png');
    //};

    p.setup = function() {
        let canvas = p.createCanvas(p.windowWidth, 750);
        canvas.parent('#js_service_bg2');
        vecLocation1 = p.createVector(0, 560);
        vecVelocity1 = p.createVector(-0.05, -0.05);
        vecLocation2 = p.createVector(window.innerWidth / 2.5, 160);
        vecVelocity2 = p.createVector(-0.05, -0.05);
        vecLocation3 = p.createVector(window.innerWidth / 3.5, 260);
        vecVelocity3 = p.createVector(-0.05, 0.05);
        vecLocation4 = p.createVector(window.innerWidth / 1.5, 500);
        vecVelocity4 = p.createVector(-0.05, -0.05);
        vecLocation5 = p.createVector(window.innerWidth, 260);
        vecVelocity5 = p.createVector(-0.05, -0.05);
    };

    p.windowResized = function() {
      p.resizeCanvas(p.windowWidth, 750);
    };

    $('#js_service_bg2').on('inview', function(event, isInView) {
    if (isInView) {
        $('#js_service_bg2').delay(100).queue(function(){
            $(this).css('opacity','1');
        });
        p.draw = function() {
            p.loop();
            vecLocation1.add(vecVelocity1);
            vecLocation2.add(vecVelocity2);
            vecLocation3.add(vecVelocity3);
            vecLocation4.add(vecVelocity4);
            vecLocation5.add(vecVelocity5);

            p.background(255);
            p.fill(255, 0);
            p.noStroke();

            let pos2 = p.map(progress2, 0, 1, 0, 3.14);
            let start2 = orientation2 + 6.28;

            let posA2 = p.map(progress3b, 0, 1, 0, 3.14);
            let posB2 = p.map(progress4b, 0, 1, 0, 3.14);
            let posC2 = p.map(progress5b, 0, 1, 0, 3.14);

            if(p.frameCount > 40){
              if(progress3b < 1.99) {
                progress3b += 0.035;
              }
            }
            if(p.frameCount > 50){
              p.noFill();
              p.stroke(0,153,68);
              p.strokeWeight(40);
              let arc06 = p.arc(vecLocation1.x, vecLocation1.y, 300, 300, 0, start2 + posA2);
            }

            p.push();
            if(p.frameCount > 30){
              if(progress4b < 1.99) {
                progress4b += 0.035;
              }
            }
            if(p.frameCount > 40){
              p.noFill();
              p.stroke(208,17,27);
              p.strokeWeight(40);            
              let arc07 = p.arc(vecLocation2.x, vecLocation2.y, 300, 300, 0, start2 + posB2);
            }
            p.pop();

            //p.image(img2, vecLocation3.x, vecLocation3.y, 230, 230);

            p.push();
            if(p.frameCount > 50){
              if(progress5b < 1.99) {
                progress5b += 0.035;
              }
            }
            if(p.frameCount > 60){
              p.noFill();
              p.stroke(253,208,0);
              p.strokeWeight(40);
              let arc09 = p.arc(vecLocation4.x, vecLocation4.y, 300, 300, 0, start2 + posC2);
            }
            p.pop();

            p.noFill();
            p.stroke(0,58,143);
            p.strokeWeight(40);
            let arc10 = p.arc(vecLocation5.x, vecLocation5.y, 300, 300, 0, start2 + pos2);
            if(progress2 < 1.99) {
                progress2 += 0.035;
            }

            if( vecLocation1.x < 0 || vecLocation1.x > window.innerWidth){
                vecVelocity1.x = vecVelocity1.x * -1;
            }
            if(vecLocation1.y < 0 || vecLocation1.y > 560){
                vecVelocity1.y = vecVelocity1.y * -1;
            }

            if( vecLocation2.x < 0 || vecLocation2.x > window.innerWidth){
                vecVelocity2.x = vecVelocity2.x * -1;
            }
            if(vecLocation2.y < 0 || vecLocation2.y > 560){
                vecVelocity2.y = vecVelocity2.y * -1;
            }

            if( vecLocation3.x < 0 || vecLocation3.x > window.innerWidth){
                vecVelocity3.x = vecVelocity3.x * -1;
            }
            if(vecLocation3.y < 0 || vecLocation3.y > 560){
                vecVelocity3.y = vecVelocity3.y * -1;
            }

            if( vecLocation4.x < 0 || vecLocation4.x > window.innerWidth){
                vecVelocity4.x = vecVelocity4.x * -1;
            }
            if(vecLocation4.y < 0 || vecLocation4.y > 560){
                vecVelocity4.y = vecVelocity4.y * -1;
            }

            if( vecLocation5.x < 0 || vecLocation5.x > window.innerWidth){
                vecVelocity5.x = vecVelocity5.x * -1;
            }
            if(vecLocation5.y < 0 || vecLocation5.y > 560){
                vecVelocity5.y = vecVelocity5.y * -1;
            }

        }
        p.mouseWheel = function(event) {
            vecLocation1.y += event.delta / 15;
            vecLocation2.y += event.delta / 15;
            vecLocation3.y += event.delta / 15;
            vecLocation4.y += event.delta / 15;
            vecLocation5.y += event.delta / 15;
        }
        } else {
            p.mouseWheel = function(event) {
                event.stopPropagation();
        }
        p.noLoop();
    }
  });
};

let myFirstSketch = new p5(sketch1);
let mySecondSketch = new p5(sketch2);
    } else {
        // 768px未満
let sketch1 = function(p) {

    //p.preload = function() {
    //    img1 = p.loadImage('https://issei-inc.co.jp/wp-content/themes/issei/img/service_bg_img.png');
    //};

    p.setup = function() {
        let canvas = p.createCanvas(window.innerWidth, 400);
        canvas.parent('#js_service_bg1');

        vecLocation1 = p.createVector(0, 350);
        vecVelocity1 = p.createVector(-0.05, -0.05);
        vecLocation2 = p.createVector(window.innerWidth / 2.5, 80);
        vecVelocity2 = p.createVector(-0.05, -0.05);
        vecLocation3 = p.createVector(window.innerWidth / 3.5, 180);
        vecVelocity3 = p.createVector(-0.05, 0.05);
        vecLocation4 = p.createVector(window.innerWidth, 300);
        vecVelocity4 = p.createVector(-0.05, -0.05);
        vecLocation5 = p.createVector(window.innerWidth, 100);
        vecVelocity5 = p.createVector(-0.05, -0.05);

    };

    $('#js_service_bg1').on('inview', function(event, isInView) {
    if (isInView) {
        $('#js_service_bg1').delay(100).queue(function(){
            $(this).css('opacity','1');
        });
        p.draw = function() {
            p.loop();
            vecLocation1.add(vecVelocity1);
            vecLocation2.add(vecVelocity2);
            vecLocation3.add(vecVelocity3);
            vecLocation4.add(vecVelocity4);
            vecLocation5.add(vecVelocity5);

            let posAsp = p.map(progress3sp, 0, 1, 0, 3.14);
            let posBsp = p.map(progress4sp, 0, 1, 0, 3.14);
            let posCsp = p.map(progress5sp, 0, 1, 0, 3.14);

            p.background(255);
            p.fill(255, 0);
            p.noStroke();

            let pos = p.map(progress, 0, 1, 0, 3.14);
            let start = orientation + 6.28;

            if(p.frameCount > 20){
              if(progress3sp < 1.99) {
                progress3sp += 0.035;
              }
            }
            if(p.frameCount > 30){
            p.noFill();
            p.stroke(0,153,68);
            p.strokeWeight(40);
            let arc01 = p.arc(vecLocation1.x, vecLocation1.y, 140, 140, 0, start + posAsp);
            }

            p.push();
            if(p.frameCount > 30){
              if(progress4sp < 1.99) {
                progress4sp += 0.035;
              }
            }
            if(p.frameCount > 40){
            p.noFill();
            p.stroke(208,17,27);
            p.strokeWeight(40);            
            let arc02 = p.arc(vecLocation2.x, vecLocation2.y, 140, 140, 0, start + posBsp);
            }
            p.pop();

            //p.image(img1, vecLocation3.x, vecLocation3.y, 110, 110);

            p.push();
            if(p.frameCount > 40){
              if(progress5sp < 1.99) {
                progress5sp += 0.035;
              }
            }
            if(p.frameCount > 50){
            p.noFill();
            p.stroke(253,208,0);
            p.strokeWeight(40);
            let arc04 = p.arc(vecLocation4.x, vecLocation4.y, 140, 140, 0, start + posCsp);
            }
            p.pop();

            p.noFill();
            p.stroke(0,58,143);
            p.strokeWeight(40);
            let arc05 = p.arc(vecLocation5.x, vecLocation5.y, 140, 140, 0, start + pos);
            if(progress < 1.99) {
                progress += 0.035;
            }

            if( vecLocation1.x < 0 || vecLocation1.x > window.innerWidth){
                vecVelocity1.x = vecVelocity1.x * -1;
            }
            if(vecLocation1.y < 0 || vecLocation1.y > 400){
                vecVelocity1.y = vecVelocity1.y * -1;
            }

            if( vecLocation2.x < 0 || vecLocation2.x > window.innerWidth){
                vecVelocity2.x = vecVelocity2.x * -1;
            }
            if(vecLocation2.y < 0 || vecLocation2.y > 400){
                vecVelocity2.y = vecVelocity2.y * -1;
            }

            if( vecLocation3.x < 0 || vecLocation3.x > window.innerWidth){
                vecVelocity3.x = vecVelocity3.x * -1;
            }
            if(vecLocation3.y < 0 || vecLocation3.y > 400){
                vecVelocity3.y = vecVelocity3.y * -1;
            }

            if( vecLocation4.x < 0 || vecLocation4.x > window.innerWidth){
                vecVelocity4.x = vecVelocity4.x * -1;
            }
            if(vecLocation4.y < 0 || vecLocation4.y > 400){
                vecVelocity4.y = vecVelocity4.y * -1;
            }

            if( vecLocation5.x < 0 || vecLocation5.x > window.innerWidth){
                vecVelocity5.x = vecVelocity5.x * -1;
            }
            if(vecLocation5.y < 0 || vecLocation5.y > 400){
                vecVelocity5.y = vecVelocity5.y * -1;
            }

        }
        p.mouseWheel = function(event) {
            vecLocation1.y += event.delta / 15;
            vecLocation2.y += event.delta / 15;
            vecLocation3.y += event.delta / 15;
            vecLocation4.y += event.delta / 15;
            vecLocation5.y += event.delta / 15;
        }
        } else {
            p.mouseWheel = function(event) {
                event.stopPropagation();
        }
        p.noLoop();
    }
  });
};

let sketch2 = function(p) {
    
    //p.preload = function() {
    //    img2 = p.loadImage('https://issei-inc.co.jp/wp-content/themes/issei/img/service_bg_img2.png');
    //};

    p.setup = function() {
        let canvas = p.createCanvas(window.innerWidth, 615);
        canvas.parent('#js_service_bg2');
        vecLocation1 = p.createVector(0, 560);
        vecVelocity1 = p.createVector(-0.05, -0.05);
        vecLocation2 = p.createVector(window.innerWidth / 2.5, 160);
        vecVelocity2 = p.createVector(-0.05, -0.05);
        vecLocation3 = p.createVector(window.innerWidth / 3.5, 260);
        vecVelocity3 = p.createVector(-0.05, 0.05);
        vecLocation4 = p.createVector(window.innerWidth, 615);
        vecVelocity4 = p.createVector(-0.05, -0.05);
        vecLocation5 = p.createVector(window.innerWidth, 260);
        vecVelocity5 = p.createVector(-0.05, -0.05);
    };

    $('#js_service_bg2').on('inview', function(event, isInView) {
    if (isInView) {
        $('#js_service_bg2').delay(100).queue(function(){
            $(this).css('opacity','1');
        });
        p.draw = function() {
            p.loop();
            vecLocation1.add(vecVelocity1);
            vecLocation2.add(vecVelocity2);
            vecLocation3.add(vecVelocity3);
            vecLocation4.add(vecVelocity4);
            vecLocation5.add(vecVelocity5);

            p.background(255);
            p.fill(255, 0);
            p.noStroke();

            let pos2 = p.map(progress2, 0, 1, 0, 3.14);
            let start2 = orientation2 + 6.28;

            let posA2sp = p.map(progress3bsp, 0, 1, 0, 3.14);
            let posB2sp = p.map(progress4bsp, 0, 1, 0, 3.14);
            let posC2sp = p.map(progress5bsp, 0, 1, 0, 3.14);

            if(p.frameCount > 40){
              if(progress3bsp < 1.99) {
                progress3bsp += 0.035;
              }
            }
            if(p.frameCount > 50){
            p.noFill();
            p.stroke(0,153,68);
            p.strokeWeight(40);
            let arc06 = p.arc(vecLocation1.x, vecLocation1.y, 140, 140, 0, start2 + posA2sp);
            }

            p.push();
            if(p.frameCount > 30){
              if(progress4bsp < 1.99) {
                progress4bsp += 0.035;
              }
            }
            if(p.frameCount > 40){
            p.noFill();
            p.stroke(208,17,27);
            p.strokeWeight(40);            
            let arc07 = p.arc(vecLocation2.x, vecLocation2.y, 140, 140, 0, start2 + posB2sp);
            }
            p.pop();

            //p.image(img2, vecLocation3.x, vecLocation3.y, 110, 110);

            p.push();
            if(p.frameCount > 50){
              if(progress5bsp < 1.99) {
                progress5bsp += 0.035;
              }
            }
            if(p.frameCount > 60){
            p.noFill();
            p.stroke(253,208,0);
            p.strokeWeight(40);
            let arc09 = p.arc(vecLocation4.x, vecLocation4.y, 140, 140, 0, start2 + posC2sp);
            }
            p.pop();

            p.noFill();
            p.stroke(0,58,143);
            p.strokeWeight(40);
            let arc10 = p.arc(vecLocation5.x, vecLocation5.y, 140, 140, 0, start2 + pos2);
            if(progress2 < 1.99) {
                progress2 += 0.035;
            }

            if( vecLocation1.x < 0 || vecLocation1.x > window.innerWidth){
                vecVelocity1.x = vecVelocity1.x * -1;
            }
            if(vecLocation1.y < 0 || vecLocation1.y > 560){
                vecVelocity1.y = vecVelocity1.y * -1;
            }

            if( vecLocation2.x < 0 || vecLocation2.x > window.innerWidth){
                vecVelocity2.x = vecVelocity2.x * -1;
            }
            if(vecLocation2.y < 0 || vecLocation2.y > 560){
                vecVelocity2.y = vecVelocity2.y * -1;
            }

            if( vecLocation3.x < 0 || vecLocation3.x > window.innerWidth){
                vecVelocity3.x = vecVelocity3.x * -1;
            }
            if(vecLocation3.y < 0 || vecLocation3.y > 560){
                vecVelocity3.y = vecVelocity3.y * -1;
            }

            if( vecLocation4.x < 0 || vecLocation4.x > window.innerWidth){
                vecVelocity4.x = vecVelocity4.x * -1;
            }
            if(vecLocation4.y < 0 || vecLocation4.y > 560){
                vecVelocity4.y = vecVelocity4.y * -1;
            }

            if( vecLocation5.x < 0 || vecLocation5.x > window.innerWidth){
                vecVelocity5.x = vecVelocity5.x * -1;
            }
            if(vecLocation5.y < 0 || vecLocation5.y > 560){
                vecVelocity5.y = vecVelocity5.y * -1;
            }

        }
        p.mouseWheel = function(event) {
            vecLocation1.y += event.delta / 15;
            vecLocation2.y += event.delta / 15;
            vecLocation3.y += event.delta / 15;
            vecLocation4.y += event.delta / 15;
            vecLocation5.y += event.delta / 15;
        }
        } else {
            p.mouseWheel = function(event) {
                event.stopPropagation();
        }
        p.noLoop();
    }
  });
};

let myFirstSketch = new p5(sketch1);
let mySecondSketch = new p5(sketch2);

    }
}

changeMediaQuery(mqs);


}
else {

let vecLocation1;
let vecVelocity1;
let vecLocation2;
let vecVelocity2;
let vecLocation3;
let vecVelocity3;
let vecLocation4;
let vecVelocity4;
let vecLocation5;
let vecVelocity5;

let img1;
let img2;

let progress = 0;
let orientation = 0;

let progress2 = 0;
let orientation2 = 0;

let progress3 = 0;
let progress4 = 0;
let progress5 = 0;
let progress6 = 0;

let progress3sp = 0;
let progress4sp = 0;
let progress5sp = 0;
let progress6sp = 0;

let progress3b = 0;
let progress4b = 0;
let progress5b = 0;
let progress6b = 0;

let progress3bsp = 0;
let progress4bsp = 0;
let progress5bsp = 0;
let progress6bsp = 0;

let angle = 0;
let count = 0;

const mqs = window.matchMedia('(min-width: 768px)');

mqs.addListener(changeMediaQuery);

function changeMediaQuery(mqs){
if (mqs.matches === true) {
        // 768px以上
let sketch1 = function(p) {

    p.preload = function() {
        img1 = p.loadImage('https://issei-inc.co.jp/wp-content/themes/issei/img/service_bg_img.png');
    };

    p.setup = function() {
        let canvas = p.createCanvas(p.windowWidth, 563);
        canvas.parent('#js_service_bg1');

        vecLocation1 = p.createVector(0, 460);
        vecVelocity1 = p.createVector(-0.05, -0.05);
        vecLocation2 = p.createVector(window.innerWidth / 2.5, 160);
        vecVelocity2 = p.createVector(-0.05, -0.05);
        vecLocation3 = p.createVector(window.innerWidth / 3.5, 260);
        vecVelocity3 = p.createVector(-0.05, 0.05);
        vecLocation4 = p.createVector(window.innerWidth / 1.5, 400);
        vecVelocity4 = p.createVector(-0.05, -0.05);
        vecLocation5 = p.createVector(window.innerWidth, 260);
        vecVelocity5 = p.createVector(-0.05, -0.05);

    };

    p.windowResized = function() {
      p.resizeCanvas(p.windowWidth, 563);
    };

    $('#js_service_bg1').on('inview', function(event, isInView) {
    if (isInView) {
        $('#js_service_bg1').delay(100).queue(function(){
            $(this).css('opacity','1');
        });
        p.draw = function() {
            p.loop();
            vecLocation1.add(vecVelocity1);
            vecLocation2.add(vecVelocity2);
            vecLocation3.add(vecVelocity3);
            vecLocation4.add(vecVelocity4);
            vecLocation5.add(vecVelocity5);

            p.background(255);
            p.fill(255, 0);
            p.noStroke();

            let pos = p.map(progress, 0, 1, 0, 3.14);
            let posA = p.map(progress3, 0, 1, 0, 3.14);
            let posB = p.map(progress4, 0, 1, 0, 3.14);
            let posC = p.map(progress5, 0, 1, 0, 3.14);

            let start = orientation + 6.28;

            if(p.frameCount > 20){
              if(progress3 < 1.99) {
                progress3 += 0.035;
              }
            }
            if(p.frameCount > 30){
              p.noFill();
              p.stroke(0,153,68);
              p.strokeWeight(40);
              let arc01 = p.arc(vecLocation1.x, vecLocation1.y, 300, 300, 0, start + posA);
            }

            p.push();

            if(p.frameCount > 30){
              if(progress4 < 1.99) {
                progress4 += 0.035;
              }
            }
            if(p.frameCount > 40){
              p.noFill();
              p.stroke(208,17,27);
              p.strokeWeight(40);
              let arc02 = p.arc(vecLocation2.x, vecLocation2.y, 300, 300, 0, start + posB);
            }
            p.pop();

            p.image(img1, vecLocation3.x, vecLocation3.y, 230, 230);

            p.push();
            if(p.frameCount > 40){
              if(progress5 < 1.99) {
                progress5 += 0.035;
              }
            }
            if(p.frameCount > 50){
              p.noFill();
              p.stroke(253,208,0);
              p.strokeWeight(40);
              let arc04 = p.arc(vecLocation4.x, vecLocation4.y, 300, 300, 0, start + posC);
            }
            p.pop();

            p.noFill();
            p.stroke(0,58,143);
            p.strokeWeight(40);
            let arc05 = p.arc(vecLocation5.x, vecLocation5.y, 300, 300, 0, start + pos);
            if(progress < 1.99) {
                progress += 0.035;
            }

            if( vecLocation1.x < 0 || vecLocation1.x > window.innerWidth){
                vecVelocity1.x = vecVelocity1.x * -1;
            }
            if(vecLocation1.y < 0 || vecLocation1.y > 460){
                vecVelocity1.y = vecVelocity1.y * -1;
            }

            if( vecLocation2.x < 0 || vecLocation2.x > window.innerWidth){
                vecVelocity2.x = vecVelocity2.x * -1;
            }
            if(vecLocation2.y < 0 || vecLocation2.y > 460){
                vecVelocity2.y = vecVelocity2.y * -1;
            }

            if( vecLocation3.x < 0 || vecLocation3.x > window.innerWidth){
                vecVelocity3.x = vecVelocity3.x * -1;
            }
            if(vecLocation3.y < 0 || vecLocation3.y > 460){
                vecVelocity3.y = vecVelocity3.y * -1;
            }

            if( vecLocation4.x < 0 || vecLocation4.x > window.innerWidth){
                vecVelocity4.x = vecVelocity4.x * -1;
            }
            if(vecLocation4.y < 0 || vecLocation4.y > 460){
                vecVelocity4.y = vecVelocity4.y * -1;
            }

            if( vecLocation5.x < 0 || vecLocation5.x > window.innerWidth){
                vecVelocity5.x = vecVelocity5.x * -1;
            }
            if(vecLocation5.y < 0 || vecLocation5.y > 460){
                vecVelocity5.y = vecVelocity5.y * -1;
            }

        }
        p.mouseWheel = function(event) {
            vecLocation1.y += event.delta / 15;
            vecLocation2.y += event.delta / 15;
            vecLocation3.y += event.delta / 15;
            vecLocation4.y += event.delta / 15;
            vecLocation5.y += event.delta / 15;
        }
        } else {
            p.mouseWheel = function(event) {
                event.stopPropagation();
        }
        p.noLoop();
    }
  });
};

let sketch2 = function(p) {
    
    p.preload = function() {
        img2 = p.loadImage('https://issei-inc.co.jp/wp-content/themes/issei/img/service_bg_img2.png');
    };

    p.setup = function() {
        let canvas = p.createCanvas(p.windowWidth, 750);
        canvas.parent('#js_service_bg2');
        vecLocation1 = p.createVector(0, 560);
        vecVelocity1 = p.createVector(-0.05, -0.05);
        vecLocation2 = p.createVector(window.innerWidth / 2.5, 160);
        vecVelocity2 = p.createVector(-0.05, -0.05);
        vecLocation3 = p.createVector(window.innerWidth / 3.5, 260);
        vecVelocity3 = p.createVector(-0.05, 0.05);
        vecLocation4 = p.createVector(window.innerWidth / 1.5, 500);
        vecVelocity4 = p.createVector(-0.05, -0.05);
        vecLocation5 = p.createVector(window.innerWidth, 260);
        vecVelocity5 = p.createVector(-0.05, -0.05);
    };

    p.windowResized = function() {
      p.resizeCanvas(p.windowWidth, 750);
    };

    $('#js_service_bg2').on('inview', function(event, isInView) {
    if (isInView) {
        $('#js_service_bg2').delay(100).queue(function(){
            $(this).css('opacity','1');
        });
        p.draw = function() {
            p.loop();
            vecLocation1.add(vecVelocity1);
            vecLocation2.add(vecVelocity2);
            vecLocation3.add(vecVelocity3);
            vecLocation4.add(vecVelocity4);
            vecLocation5.add(vecVelocity5);

            p.background(255);
            p.fill(255, 0);
            p.noStroke();

            let pos2 = p.map(progress2, 0, 1, 0, 3.14);
            let start2 = orientation2 + 6.28;

            let posA2 = p.map(progress3b, 0, 1, 0, 3.14);
            let posB2 = p.map(progress4b, 0, 1, 0, 3.14);
            let posC2 = p.map(progress5b, 0, 1, 0, 3.14);

            if(p.frameCount > 40){
              if(progress3b < 1.99) {
                progress3b += 0.035;
              }
            }
            if(p.frameCount > 50){
              p.noFill();
              p.stroke(0,153,68);
              p.strokeWeight(40);
              let arc06 = p.arc(vecLocation1.x, vecLocation1.y, 300, 300, 0, start2 + posA2);
            }

            p.push();
            if(p.frameCount > 30){
              if(progress4b < 1.99) {
                progress4b += 0.035;
              }
            }
            if(p.frameCount > 40){
              p.noFill();
              p.stroke(208,17,27);
              p.strokeWeight(40);            
              let arc07 = p.arc(vecLocation2.x, vecLocation2.y, 300, 300, 0, start2 + posB2);
            }
            p.pop();

            p.image(img2, vecLocation3.x, vecLocation3.y, 230, 230);

            p.push();
            if(p.frameCount > 50){
              if(progress5b < 1.99) {
                progress5b += 0.035;
              }
            }
            if(p.frameCount > 60){
              p.noFill();
              p.stroke(253,208,0);
              p.strokeWeight(40);
              let arc09 = p.arc(vecLocation4.x, vecLocation4.y, 300, 300, 0, start2 + posC2);
            }
            p.pop();

            p.noFill();
            p.stroke(0,58,143);
            p.strokeWeight(40);
            let arc10 = p.arc(vecLocation5.x, vecLocation5.y, 300, 300, 0, start2 + pos2);
            if(progress2 < 1.99) {
                progress2 += 0.035;
            }

            if( vecLocation1.x < 0 || vecLocation1.x > window.innerWidth){
                vecVelocity1.x = vecVelocity1.x * -1;
            }
            if(vecLocation1.y < 0 || vecLocation1.y > 560){
                vecVelocity1.y = vecVelocity1.y * -1;
            }

            if( vecLocation2.x < 0 || vecLocation2.x > window.innerWidth){
                vecVelocity2.x = vecVelocity2.x * -1;
            }
            if(vecLocation2.y < 0 || vecLocation2.y > 560){
                vecVelocity2.y = vecVelocity2.y * -1;
            }

            if( vecLocation3.x < 0 || vecLocation3.x > window.innerWidth){
                vecVelocity3.x = vecVelocity3.x * -1;
            }
            if(vecLocation3.y < 0 || vecLocation3.y > 560){
                vecVelocity3.y = vecVelocity3.y * -1;
            }

            if( vecLocation4.x < 0 || vecLocation4.x > window.innerWidth){
                vecVelocity4.x = vecVelocity4.x * -1;
            }
            if(vecLocation4.y < 0 || vecLocation4.y > 560){
                vecVelocity4.y = vecVelocity4.y * -1;
            }

            if( vecLocation5.x < 0 || vecLocation5.x > window.innerWidth){
                vecVelocity5.x = vecVelocity5.x * -1;
            }
            if(vecLocation5.y < 0 || vecLocation5.y > 560){
                vecVelocity5.y = vecVelocity5.y * -1;
            }

        }
        p.mouseWheel = function(event) {
            vecLocation1.y += event.delta / 15;
            vecLocation2.y += event.delta / 15;
            vecLocation3.y += event.delta / 15;
            vecLocation4.y += event.delta / 15;
            vecLocation5.y += event.delta / 15;
        }
        } else {
            p.mouseWheel = function(event) {
                event.stopPropagation();
        }
        p.noLoop();
    }
  });
};

let myFirstSketch = new p5(sketch1);
let mySecondSketch = new p5(sketch2);
    } else {
        // 768px未満
let sketch1 = function(p) {

    p.preload = function() {
        img1 = p.loadImage('https://issei-inc.co.jp/wp-content/themes/issei/img/service_bg_img.png');
    };

    p.setup = function() {
        let canvas = p.createCanvas(window.innerWidth, 368);
        canvas.parent('#js_service_bg1');

        vecLocation1 = p.createVector(0, 350);
        vecVelocity1 = p.createVector(-0.05, -0.05);
        vecLocation2 = p.createVector(window.innerWidth / 2.5, 80);
        vecVelocity2 = p.createVector(-0.05, -0.05);
        vecLocation3 = p.createVector(window.innerWidth / 3.5, 180);
        vecVelocity3 = p.createVector(-0.05, 0.05);
        vecLocation4 = p.createVector(window.innerWidth, 300);
        vecVelocity4 = p.createVector(-0.05, -0.05);
        vecLocation5 = p.createVector(window.innerWidth, 100);
        vecVelocity5 = p.createVector(-0.05, -0.05);

    };

    $('#js_service_bg1').on('inview', function(event, isInView) {
    if (isInView) {
        $('#js_service_bg1').delay(100).queue(function(){
            $(this).css('opacity','1');
        });
        p.draw = function() {
            p.loop();
            vecLocation1.add(vecVelocity1);
            vecLocation2.add(vecVelocity2);
            vecLocation3.add(vecVelocity3);
            vecLocation4.add(vecVelocity4);
            vecLocation5.add(vecVelocity5);

            let posAsp = p.map(progress3sp, 0, 1, 0, 3.14);
            let posBsp = p.map(progress4sp, 0, 1, 0, 3.14);
            let posCsp = p.map(progress5sp, 0, 1, 0, 3.14);

            p.background(255);
            p.fill(255, 0);
            p.noStroke();

            let pos = p.map(progress, 0, 1, 0, 3.14);
            let start = orientation + 6.28;

            if(p.frameCount > 20){
              if(progress3sp < 1.99) {
                progress3sp += 0.035;
              }
            }
            if(p.frameCount > 30){
            p.noFill();
            p.stroke(0,153,68);
            p.strokeWeight(40);
            let arc01 = p.arc(vecLocation1.x, vecLocation1.y, 140, 140, 0, start + posAsp);
            }

            p.push();
            if(p.frameCount > 30){
              if(progress4sp < 1.99) {
                progress4sp += 0.035;
              }
            }
            if(p.frameCount > 40){
            p.noFill();
            p.stroke(208,17,27);
            p.strokeWeight(40);            
            let arc02 = p.arc(vecLocation2.x, vecLocation2.y, 140, 140, 0, start + posBsp);
            }
            p.pop();

            p.image(img1, vecLocation3.x, vecLocation3.y, 110, 110);

            p.push();
            if(p.frameCount > 40){
              if(progress5sp < 1.99) {
                progress5sp += 0.035;
              }
            }
            if(p.frameCount > 50){
            p.noFill();
            p.stroke(253,208,0);
            p.strokeWeight(40);
            let arc04 = p.arc(vecLocation4.x, vecLocation4.y, 140, 140, 0, start + posCsp);
            }
            p.pop();

            p.noFill();
            p.stroke(0,58,143);
            p.strokeWeight(40);
            let arc05 = p.arc(vecLocation5.x, vecLocation5.y, 140, 140, 0, start + pos);
            if(progress < 1.99) {
                progress += 0.035;
            }

            if( vecLocation1.x < 0 || vecLocation1.x > window.innerWidth){
                vecVelocity1.x = vecVelocity1.x * -1;
            }
            if(vecLocation1.y < 0 || vecLocation1.y > 400){
                vecVelocity1.y = vecVelocity1.y * -1;
            }

            if( vecLocation2.x < 0 || vecLocation2.x > window.innerWidth){
                vecVelocity2.x = vecVelocity2.x * -1;
            }
            if(vecLocation2.y < 0 || vecLocation2.y > 400){
                vecVelocity2.y = vecVelocity2.y * -1;
            }

            if( vecLocation3.x < 0 || vecLocation3.x > window.innerWidth){
                vecVelocity3.x = vecVelocity3.x * -1;
            }
            if(vecLocation3.y < 0 || vecLocation3.y > 400){
                vecVelocity3.y = vecVelocity3.y * -1;
            }

            if( vecLocation4.x < 0 || vecLocation4.x > window.innerWidth){
                vecVelocity4.x = vecVelocity4.x * -1;
            }
            if(vecLocation4.y < 0 || vecLocation4.y > 400){
                vecVelocity4.y = vecVelocity4.y * -1;
            }

            if( vecLocation5.x < 0 || vecLocation5.x > window.innerWidth){
                vecVelocity5.x = vecVelocity5.x * -1;
            }
            if(vecLocation5.y < 0 || vecLocation5.y > 400){
                vecVelocity5.y = vecVelocity5.y * -1;
            }

        }
        p.mouseWheel = function(event) {
            vecLocation1.y += event.delta / 15;
            vecLocation2.y += event.delta / 15;
            vecLocation3.y += event.delta / 15;
            vecLocation4.y += event.delta / 15;
            vecLocation5.y += event.delta / 15;
        }
        } else {
            p.mouseWheel = function(event) {
                event.stopPropagation();
        }
        p.noLoop();
    }
  });
};

let sketch2 = function(p) {
    
    p.preload = function() {
        img2 = p.loadImage('https://issei-inc.co.jp/wp-content/themes/issei/img/service_bg_img2.png');
    };

    p.setup = function() {
        let canvas = p.createCanvas(window.innerWidth, 615);
        canvas.parent('#js_service_bg2');
        vecLocation1 = p.createVector(0, 560);
        vecVelocity1 = p.createVector(-0.05, -0.05);
        vecLocation2 = p.createVector(window.innerWidth / 2.5, 160);
        vecVelocity2 = p.createVector(-0.05, -0.05);
        vecLocation3 = p.createVector(window.innerWidth / 3.5, 260);
        vecVelocity3 = p.createVector(-0.05, 0.05);
        vecLocation4 = p.createVector(window.innerWidth, 615);
        vecVelocity4 = p.createVector(-0.05, -0.05);
        vecLocation5 = p.createVector(window.innerWidth, 260);
        vecVelocity5 = p.createVector(-0.05, -0.05);
    };

    $('#js_service_bg2').on('inview', function(event, isInView) {
    if (isInView) {
        $('#js_service_bg2').delay(100).queue(function(){
            $(this).css('opacity','1');
        });
        p.draw = function() {
            p.loop();
            vecLocation1.add(vecVelocity1);
            vecLocation2.add(vecVelocity2);
            vecLocation3.add(vecVelocity3);
            vecLocation4.add(vecVelocity4);
            vecLocation5.add(vecVelocity5);

            p.background(255);
            p.fill(255, 0);
            p.noStroke();

            let pos2 = p.map(progress2, 0, 1, 0, 3.14);
            let start2 = orientation2 + 6.28;

            let posA2sp = p.map(progress3bsp, 0, 1, 0, 3.14);
            let posB2sp = p.map(progress4bsp, 0, 1, 0, 3.14);
            let posC2sp = p.map(progress5bsp, 0, 1, 0, 3.14);

            if(p.frameCount > 40){
              if(progress3bsp < 1.99) {
                progress3bsp += 0.035;
              }
            }
            if(p.frameCount > 50){
            p.noFill();
            p.stroke(0,153,68);
            p.strokeWeight(40);
            let arc06 = p.arc(vecLocation1.x, vecLocation1.y, 140, 140, 0, start2 + posA2sp);
            }

            p.push();
            if(p.frameCount > 30){
              if(progress4bsp < 1.99) {
                progress4bsp += 0.035;
              }
            }
            if(p.frameCount > 40){
            p.noFill();
            p.stroke(208,17,27);
            p.strokeWeight(40);            
            let arc07 = p.arc(vecLocation2.x, vecLocation2.y, 140, 140, 0, start2 + posB2sp);
            }
            p.pop();

            p.image(img2, vecLocation3.x, vecLocation3.y, 110, 110);

            p.push();
            if(p.frameCount > 50){
              if(progress5bsp < 1.99) {
                progress5bsp += 0.035;
              }
            }
            if(p.frameCount > 60){
            p.noFill();
            p.stroke(253,208,0);
            p.strokeWeight(40);
            let arc09 = p.arc(vecLocation4.x, vecLocation4.y, 140, 140, 0, start2 + posC2sp);
            }
            p.pop();

            p.noFill();
            p.stroke(0,58,143);
            p.strokeWeight(40);
            let arc10 = p.arc(vecLocation5.x, vecLocation5.y, 140, 140, 0, start2 + pos2);
            if(progress2 < 1.99) {
                progress2 += 0.035;
            }

            if( vecLocation1.x < 0 || vecLocation1.x > window.innerWidth){
                vecVelocity1.x = vecVelocity1.x * -1;
            }
            if(vecLocation1.y < 0 || vecLocation1.y > 560){
                vecVelocity1.y = vecVelocity1.y * -1;
            }

            if( vecLocation2.x < 0 || vecLocation2.x > window.innerWidth){
                vecVelocity2.x = vecVelocity2.x * -1;
            }
            if(vecLocation2.y < 0 || vecLocation2.y > 560){
                vecVelocity2.y = vecVelocity2.y * -1;
            }

            if( vecLocation3.x < 0 || vecLocation3.x > window.innerWidth){
                vecVelocity3.x = vecVelocity3.x * -1;
            }
            if(vecLocation3.y < 0 || vecLocation3.y > 560){
                vecVelocity3.y = vecVelocity3.y * -1;
            }

            if( vecLocation4.x < 0 || vecLocation4.x > window.innerWidth){
                vecVelocity4.x = vecVelocity4.x * -1;
            }
            if(vecLocation4.y < 0 || vecLocation4.y > 560){
                vecVelocity4.y = vecVelocity4.y * -1;
            }

            if( vecLocation5.x < 0 || vecLocation5.x > window.innerWidth){
                vecVelocity5.x = vecVelocity5.x * -1;
            }
            if(vecLocation5.y < 0 || vecLocation5.y > 560){
                vecVelocity5.y = vecVelocity5.y * -1;
            }

        }
        p.mouseWheel = function(event) {
            vecLocation1.y += event.delta / 15;
            vecLocation2.y += event.delta / 15;
            vecLocation3.y += event.delta / 15;
            vecLocation4.y += event.delta / 15;
            vecLocation5.y += event.delta / 15;
        }
        } else {
            p.mouseWheel = function(event) {
                event.stopPropagation();
        }
        p.noLoop();
    }
  });
};

let myFirstSketch = new p5(sketch1);
let mySecondSketch = new p5(sketch2);

    }
}

changeMediaQuery(mqs);

}

$(window).on('load resize', function(){
  let w = $(window).width();
  if(w <= 768){
    $('.ly_mv').height(window.innerHeight　-　129 + 'px');
    $('#js_splash-ttl').height(window.innerHeight + 'px');
  }else{
    $('.ly_mv').height('calc(100vh - 280px)');
  }
});
